<template>
  <div>
    <h2 style="text-align: center;margin-bottom: 2em">
      {{ invoice.InvoiceType }}ной инвойс {{ invoice.InvoiceNumber }}
    </h2>
    <a-row>
      <a-col :span="12">
        <ul class="custom-list">
          <li>
            <b>Дата создание</b>: {{ invoice.CreatedAt }}
          </li>
          <li v-if="invoice.Warehouse">
            <b>Склад</b>: {{ invoice.Warehouse.Name }}
          </li>
          <li v-if="invoice.User">
            <b>Ответсвенное лицо</b>: {{ invoice.User.FirstName }} {{ invoice.User.LastName }}
          </li>
        </ul>
      </a-col>
      <a-col :span="12">
        <ul class="custom-list">
          <li v-if="invoice.Vendor">
            <b>Поставщик</b>: {{ invoice.Vendor.Name }}
          </li>
          <li>
            <b>Статус</b>: {{ invoice.Status }}
          </li>
          <li v-if="invoice.OutputOverhead">
            <b>На основе накладного</b>: № {{ invoice.OutputOverhead.Id }}
          </li>
          <li v-if="invoice.InputOverhead">
            <b>На основе накладного</b>: № {{ invoice.InputOverhead.Id }}
          </li>
        </ul>
      </a-col>
    </a-row>
    <a-col v-if="invoice.Comment">
      <p style="padding-left: 40px"><strong>Комментарий</strong></p>
      <p style="padding-left: 40px">
        {{ invoice.Comment }}
      </p>

    </a-col>
    <a-table
      style="margin-top: 2em"
      :columns="columns"
      :rowKey="record => record.Id"
      :dataSource="invoice.InvoiceItems"
      :pagination="false"
    >
    <template slot="Price" slot-scope="price, record">
      {{ record.Amount * price }}
    </template>
    <template slot="attributes" slot-scope="attributes">
      <div v-for="(item, index) in attributes" :key="index">
        <div
          class="di"
          v-for="(attribute, attributeIndex) in grouped(item.Attributes)"
          :key="attributeIndex">
          <b>{{ attribute.Name }}</b>: {{ mapped(attribute.Value) }}
        </div>
        <a-divider type="horizontal" />
      </div>
    </template>
    <span slot="action" slot-scope="item">
      <a-button-group>
        <a-button type="primary">
          <router-link :to="{ name: 'products-edit', params: { productId: item.Id } }">
            Редактировать
          </router-link>
        </a-button>
          <a-popconfirm
            title="Действительно собираетесь удалить запись?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="destroy(item)"
          >
            <a-button type="danger">
              Удалить
            </a-button>
          </a-popconfirm>
      </a-button-group>
    </span>
    </a-table>
  </div>
</template>
<style>
.custom-list {
  font-size: 11pt;
  list-style: none;
}
</style>
<script>
import _ from 'lodash';

export default {
  data: () => ({
    invoice: {
      Warehouse: [],
      Vendor: [],
    },
    columns: [
      {
        title: 'Наименование',
        dataIndex: 'Product.Name',
      },
      {
        title: 'Кол-во',
        dataIndex: 'Amount',
      },
      {
        title: 'Ед.Имерение',
        dataIndex: 'Product.Measurement.Name',
      },
      {
        title: 'Цена',
        key: 'Price.*',
        dataIndex: 'Price',
      },
      {
        title: 'Сумма',
        dataIndex: 'Price',
        scopedSlots: { customRender: 'Price' },
      },
      {
        title: 'Атрибуты',
        dataIndex: 'Items',
        scopedSlots: { customRender: 'attributes' },
      },
    ],
  }),
  mounted() {
    this.fetch();
  },
  methods: {
    mapped(val) {
      return _.map(val, 'Value').join(', ');
    },
    grouped(arrayData) {
      return _.chain(arrayData)
        .groupBy('Name')
        .map((value, key) => ({ Name: key, Value: value }))
        .value();
    },
    fetch() {
      this.$api.getInvoiceDetail(this.$router.currentRoute.params, false, (response) => {
        const { data } = response;
        this.invoice = data.data;
      });
    },
  },
};
</script>
